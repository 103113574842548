import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {},
};

export const filterSlice = createSlice({
  name: "filterData",
  initialState,
  reducers: {
    setFilterData: (state, action) => {
      state.filterData = {
        ...state.filterData,
        ...action.payload, // Merge new data with existing data
      };
    },
    clearFilterData: (state) => {
      state.filterData = {}; // Reset filterData to an empty object
    },
  },
});

export const filterSelector = (state: any) => state.filterData.filterData;

const { actions, reducer } = filterSlice;

export const { setFilterData, clearFilterData } = actions;

export default reducer;
