import { BounceLoader } from "react-spinners";

interface LoaderProps {
  color?: string;
  size?: number;
}

const Loader = ({ color = "#4A90E2", size = 60 }: LoaderProps) => (
  <div className="loader-container">
    <BounceLoader color={color} size={size} />
  </div>
);

export default Loader;


// import React from "react";

// const Loader = () => {
//   return (
//     <div className="loader-container">
//       <div className="spinner"></div>
//     </div>
//   );
// };

// export default Loader;