import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationListData: null,
  notificationCount: 0, // Added state for notification count
};

export const notificationPrefSlice = createSlice({
  name: "notificationListData",
  initialState,
  reducers: {
    setNotificationPref: (state, action) => {
      state.notificationListData = action.payload;
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload;
    },
  },
});

export const notificationPrefSelector = (state: any) =>
  state.notificationListData.notificationListData;

export const notificationCountSelector = (state: any) =>
  state.notificationListData.notificationCount;

const { actions, reducer } = notificationPrefSlice;

export const { setNotificationPref, setNotificationCount } = actions;

export default reducer;
