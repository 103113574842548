import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, userSelector } from "../../redux/slices/userSlice";


const WelcomeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(userSelector);

  const welcomeVideo = userData?.welcomeVideo?.video;
  const welcomeTitle = userData?.welcomeVideo?.title;
  const isWelcomeVideoWatch = userData?.isWelcomeVideoWatch; // Get this value from Redux

  useEffect(() => {
    const isWatched = sessionStorage.getItem("isWelcomeVideoWatched");
    if (!isWatched && isWelcomeVideoWatch === null && welcomeVideo) {
      setIsOpen(true);
    }
  }, [isWelcomeVideoWatch, welcomeVideo]);

  const closeModal = () => {
    setIsOpen(false);
    sessionStorage.setItem("isWelcomeVideoWatched", "true");

    dispatch(setUser({ ...userData, isWelcomeVideoWatch: true }));
  };

  if (!isOpen) return null; // Don't render if modal is closed

  return ( 
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg max-w-lg shadow-lg relative">
        <h2 className="text-xl font-bold mb-4">{welcomeTitle || "Welcome"}</h2>
        {welcomeVideo ? (
          <video controls className="w-full rounded-lg" onEnded={closeModal}>
            <source src={welcomeVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>No welcome video available.</p>
        )}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={closeModal}
        >
          ✖
        </button>
      </div>
    </div>
  );
};

export default WelcomeModal;
