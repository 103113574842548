import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Interest } from "../../interface/Interest";

export interface IUserInitialRedux {
  profile: Profile | null;
  UserDetails: ApiResponse | null; // Store full API response
}

interface Country {
  id: number;
  name: string;
  code: string;
}

interface CoverColor {
  id: number;
  hexcode: string;
  name: string;
}

interface Profile {
  id?: number;
  avatar: string;
  country: Country;
  followers: number;
  post: number;
  nickname: string;
  description: string;
  interest: Interest[];
  phoneNb?: number;
  textColor?: string;
  backgroundColor?: string;
  coverColor?: CoverColor;
  coverImage?: string;
}




interface ApiResponse {
  code: number;
  total: number;
  totalPublications: number;
  next: string | null;
  previous: string | null;
  publications: Publication[];
  followers: Follower[];
  galleryMembers: any[]; // Adjust if needed
  users: any | null; // Adjust if needed
}

interface Publication {
  video: string | null;
  images: string | null;
  id: number;
  type: string;
  text: string | null;
  title: string;
  createdAt: string;
  likedByMe: boolean;
  countLikes: number;
  isRepostedByMe: boolean;
  countComments: number;
  art: Art;
  event: any | null;
  user: User;
  pinnedBy: any[];
  likes: any[];
  comments: any[];
  isPinned: boolean;
}

interface Art {
  images: any[];
  video: string | null;
  id: number;
  title: string;
  price: number;
  taxRate: string;
  description: string;
  creationYear: string;
  weight: number;
  width: number;
  height: number;
  depth: number;
  status: string;
  purchaseDate: string | null;
  artImage: string | null;
  framed: boolean;
  artMinImage: string | null;
  artVideo: string | null;
  mediaType: number;
  deletedAt: string | null;
  type: number;
  quantity: number;
  priceChanges: number;
  galleryId: number | null;
  publicationDate: string;
  idCreatorTag: number;
  publisher: number;
  buyer: number | null;
  categoryId: number;
  mainColorId: number;
  secondaryColorId: number;
  publicationId: number;
  category: Category;
  creator: Creator;
  mainColor: Color;
  secondaryColor: Color;
  owner: Owner;
}

interface Category {
  id: number;
  name: string;
}

interface Creator {
  id: number;
  name: string;
  type: number;
}

interface Color {
  id: number;
  name: string;
  hexcode: string;
  createdAt: string;
  updatedAt: string;
}

interface Owner {
  id: number;
  name: string;
  surname: string;
  nickname: string;
  mail: string;
  avatar: string;
  phoneNb: string | null;
  description: string;
  countryId: number;
  city: string;
  zip: string;
  backgroundColor: string;
  textColor: string | null;
  joinDate: string;
  deletedAt: string | null;
}

interface User {
  id: number;
  name: string;
  surname: string;
  avatar: string;
  nickname: string;
  mail: string;
  mainProfileId: number | null;
  GamificationSelectedShopItem: any | null;
  Subscriptions: any[];
}

interface Follower {
  id: number;
  name: string;
  surname: string;
  avatar: string;
  nickname: string;
  city: string;
  country: Country;
  Subscriptions: any[];
  followedByMe: boolean;
}

interface Country {
  id: number;
  name: string;
}








const initialState: IUserInitialRedux = {
  profile: null,
  UserDetails: null // Store full API response // <-- Initialize totalPublications
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile | null>) => {
      state.profile = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<ApiResponse | null>) => {
      state.UserDetails = action.payload; // Store full API response
      console.log(state.UserDetails,"------------> moooj")
    },
  },
});

export const profileSelector = (state: { profile: IUserInitialRedux }) =>
  state.profile.profile;

export const totalPublicationsSelector = (state: { profile: IUserInitialRedux }) =>
  state.profile.UserDetails;   // <-- Selector for user Data

const { actions, reducer } = profileSlice;

export const { setProfile, setUserDetails } = actions; // <-- Export action
export const setprofile = (data: Profile | null) => {
  setProfile(data);
};
export default reducer;
