import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "./web.css";
import "./App.css";
import ToastNotification from "./components/core/ToastNotifications";
import Loader from "./components/core/Loader";
import WelcomeModal from "./components/core/WelcomeModal";


function App() {
  return (
    <>
      {/* <Loader /> */}
      <WelcomeModal />
      <AppRoutes />
      <ToastNotification />

    </>
  );
}

export default App;
