import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface BackgroundColorState {
  backgroundColor: string | null; // Default is null
}

const initialState: BackgroundColorState = {
  backgroundColor: null, // No default color
};

export const backgroundColorSlice = createSlice({
  name: "backgroundColor",
  initialState,
  reducers: {
    setBackgroundColor: (state, action: PayloadAction<string | null>) => {
      state.backgroundColor = action.payload; // ✅ Updates background color
    },
  },
});

// ✅ Selector to get background color
export const backgroundColorSelector = (state: { backgroundColor: BackgroundColorState }) =>
  state?.backgroundColor?.backgroundColor;

const { actions, reducer } = backgroundColorSlice;

export const { setBackgroundColor } = actions;

export default reducer;
