import French from "../assets/images/flag_french.svg";
import China from "../assets/images/flag_china.svg";
import English from "../assets/images/flag_english.svg";
import Hindi from "../assets/images/flag_hindi.svg";
import Arabic from "../assets/images/flag_arabic.svg";
import German from "../assets/images/flag_german.svg";
import Italy from "../assets/images/flag_italy.svg";
import Japan from "../assets/images/flag_japan.svg";
import Netherland from "../assets/images/flag_netherland.svg";
import Portugal from "../assets/images/flag_portugal.svg";
import Spanish from "../assets/images/flag_spanish.svg";
import Swedish from "../assets/images/flag_swedesh.svg";
import { pathRoute } from "../routes/pathRoute";
import { ReactComponent as IconSocial } from "../assets/images/icon_social.svg";
import { ReactComponent as IconMasterClass } from "../assets/images/icon_masterclass.svg";
import { ReactComponent as IconOpportunities } from "../assets/images/icon_opportunities.svg";
import { ReactComponent as IconCreatePost } from "../assets/images/icon_createpost.svg";
import { ReactComponent as IconMyPurchase } from "../assets/images/icon_my_purchase.svg";
import { ReactComponent as IconSales } from "../assets/images/icon_sales.svg";
import { ReactComponent as IconSideMasterClass } from "../assets/images/icon_sideMasterclass.svg";
import { ReactComponent as IconMyTransaction } from "../assets/images/icon_mytransaction.svg";
import { ReactComponent as IconPaymentMethods } from "../assets/images/icon_paymentmethods.svg";
import { ReactComponent as IconWebStripe } from "../assets/images/icon_webstripe.svg";
import { ReactComponent as IconSideSettings } from "../assets/images/icon_sideSettings.svg";
import { ReactComponent as IconPiece } from "../assets/images/icon_web_piece.svg";
import { ReactComponent as IconPeople } from "../assets/images/icon_people.svg";
import { ReactComponent as IconDeleteAccount } from "../assets/images/icon_delete_grey.svg";

export const ProductStatus = {
  Exposed: "visible",
  OnSale: "sellable",
  Sold: "sold",
  OnStandBy: "standby",
  processing: "processing",
};
export const DEFAULT_DEVISE = "€";
export const CONSTANTS = {
  FRENCH_ABV: "fr",
  ENGLISH_ABV: "en",
};

export const OpportunityType = {
  Exhibition: 0,
  Collaboration: 1,
};

export const HTTP_STRING = "http://";
export const HTTPS_STRING = "https://";
export const WWW_STRING = "www.";

export const countryOptions = [
  {
    label: "Arabic",
    value: "ar",
    image: Arabic,
  },
  {
    label: "Chinese",
    value: "zh",
    image: China,
  },
  {
    label: "French",
    value: "fr",
    image: French,
    selected: true,
  },
  {
    label: "English",
    value: "en",
    image: English,
  },
  {
    label: "Japanese",
    value: "ja",
    image: Japan,
  },
  {
    label: "German",
    value: "de",
    image: German,
  },
  {
    label: "Italian",
    value: "it",
    image: Italy,
  },
  {
    label: "Spanish",
    value: "es",
    image: Spanish,
  },
  {
    label: "Swedish",
    value: "sv",
    image: Swedish,
  },
  {
    label: "Potuguese",
    value: "pt",
    image: Portugal,
  },
  {
    label: "Dutch",
    value: "nl",
    image: Netherland,
  },
  {
    label: "Hindi",
    value: "hi",
    image: Hindi,
  },
];

export const sideBarItems = [
  {
    name: "Social",
    href: pathRoute.auth.social,
    icon: IconSocial,
    submenu: false,
    hidden: true,
    requireAuth: false,
  },
  {
    name: "Piece",
    href: pathRoute.website.piece,
    icon: IconPiece,
    submenu: false,
    hidden: true,
    requireAuth: false,
  },
  {
    name: "People",
    href: pathRoute.website.people,
    icon: IconPeople,
    submenu: false,
    hidden: true,
    requireAuth: false,
  },
  {
    name: "Masterclass",
    href: pathRoute.website.masterClass,
    icon: IconMasterClass,
    submenu: false,
    hidden: true,
    requireAuth: true, // Show only when logged in
  },
  {
    name: "Opportunities",
    href: pathRoute.website.opportunities,
    icon: IconOpportunities,
    submenu: false,
    hidden: true,
    requireAuth: false,
  },
  {
    name: "Create Post",
    href: pathRoute.website.createPost,
    icon: IconCreatePost,
    submenu: false,
    hidden: true,
    requireAuth: true,
  },
];

export const orderSidebarItems = [
  {
    name: "My Purchase",
    href: pathRoute.website.myPurchase,
    icon: IconMyPurchase,
    submenu: false,
    hidden: true,
    labelName: "my_purchase",
    requireAuth: true,
  },
  {
    name: "My Sales",
    href: pathRoute.website.mySales,
    icon: IconSales,
    submenu: false,
    hidden: true,
    labelName: "my_sales",
    requireAuth: true,
  },
  {
    name: "My Masterclass",
    href: pathRoute.website.myMasterclass,
    icon: IconSideMasterClass,
    submenu: false,
    hidden: true,
    labelName: "my_masterclass",
    requireAuth: true,
  },
  {
    name: "My Transaction",
    href: pathRoute.website.myTransactions,
    icon: IconMyTransaction,
    submenu: false,
    hidden: true,
    labelName: "my_transaction",
    requireAuth: true,
  },
];
export const settingsSidebarItems = [
  {
    name: "Payment Methods",
    href: pathRoute.website.paymentMethods,
    icon: IconPaymentMethods,
    submenu: false,
    hidden: true,
    labelName: "payment_methods",
    requireAuth: true,
  },
  {
    name: "Stripe",
    href: pathRoute.website.stripe,
    icon: IconWebStripe,
    submenu: false,
    hidden: true,
    labelName: "stripe",
    requireAuth: true,
  },
  {
    name: "Settings",
    href: pathRoute.website.settings,
    icon: IconSideSettings,
    submenu: false,
    hidden: true,
    labelName: "settings",
    requireAuth: false,
  },
  {
    name: "Delete Account",
    href: pathRoute.website.deleteAccount,
    icon: IconDeleteAccount,
    submenu: false,
    hidden: true,
    labelName: "delete_account",
    requireAuth: true,
  },
];

export const COUNT_INTERVAL: number = 60000 * 2; // 2 minutes

export const notificationType = {
  Like: "0",
  Comment: "1",
  Follow: "2",
  Purshase: "3",
  Favorite: "4",
  Create: "6",
  Selling: "5",
  LikeComment: "7",
  Response: "8",
  LikeResponse: "9",
  Acceptance: "10",
  Request: "11",
  Event: "12",
  MediaLikeComment: "13",
  MediaResponse: "14",
  MediaLikeResponse: "15",
  MasterPieceShare:"17",
  PopularityUpdate:"18",
  InactiveFromWhile:"28",
  EngagementEncouragement:"31"
};
