import { lazy } from 'react';
import { pathRoute } from './pathRoute';
const Profile = lazy(() => import('../pages/portfolio').then(({ Profile }) => ({ default: Profile })));
const ArtRoomDetail = lazy(() => import('../pages/portfolio/art-room-detail/ArtRoomDetail'));
const Checkout = lazy(() => import('../pages/portfolio/checkout/Checkout'));
const FeedCommentDetail = lazy(() => import('../pages/portfolio/feed-comment/FeedComment'));
const Login = lazy(() => import('../pages/website/authentication/Login'));
const Register = lazy(() => import('../pages/website/authentication/Register'));
const ProfileSetup = lazy(() => import('../pages/website/authentication/profile-setup/ProfileSetup'));
const OTP = lazy(() => import('../pages/website/authentication/OTP'));
const ForgotPassword = lazy(() => import('../pages/website/authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/website/authentication/ResetPassword'));
const Social = lazy(() => import('../pages/website/social/Social'));
const Opportunities = lazy(() => import('../pages/website/opportunities/Opportunities'));
const OpportunityDetail = lazy(() => import('../pages/website/opportunities/OpportunityDetail'));
const UserProfile = lazy(() => import('../pages/website/user-profile/UserProfile'));
const Settings = lazy(() => import('../pages/website/my-account/web-settings/settings/Settings'));
const SearchList = lazy(() => import('../pages/website/search/SearchList'));
const Piece = lazy(() => import('../pages/website/piece/Piece'));
const People = lazy(() => import('../pages/website/people/People'));
const ProfileArtRoomDetails = lazy(() => import('../pages/website/my-profile/artroom/ProfileArtRoomdetails'));
const MasterClass = lazy(() => import('../pages/website/masterclass/MasterClass'));
const MyPurchase = lazy(() => import('../pages/website/my-account/orders/MyPurchase'));
const MySales = lazy(() => import('../pages/website/my-account/orders/MySales'));
const MyMasterclass = lazy(() => import('../pages/website/my-account/orders/MyMasterclass'));
const MyTransactions = lazy(() => import('../pages/website/my-account/orders/MyTransactions'));
const PaymentMethods = lazy(() => import('../pages/website/my-account/web-settings/PaymentMethods'));
const StripeMain = lazy(() => import('../pages/website/my-account/web-settings/StripeMain'));
const EditProfile = lazy(() => import('../pages/website/my-profile/EditProfile'));
const ArtCheckout = lazy(() => import('../pages/website/my-profile/artroom/checkout/ArtCheckout'));
const CreatePost = lazy(() => import('../pages/website/createPost/CreatePost'));
const MasterClassDetail = lazy(() => import('../pages/website/masterclass/MasterClassDetail'));
const MyProfile = lazy(() => import('../pages/website/my-profile/MyProfile'));
const PurchaseDetail = lazy(() => import('../pages/website/my-account/orders/PurchaseDetail'));
const SaleDetail = lazy(() => import('../pages/website/my-account/orders/SaleDetail'));
const CreateEvent = lazy(() => import('../pages/website/my-profile/CreateEvent'));
const StripeConnect = lazy(() => import('../pages/website/my-account/web-settings/StripeConnect'));
const Notifications = lazy(() => import('../pages/website/notifications/Notifications'));
const WrapAddCard = lazy(() => import('../pages/website/my-account/web-settings/WrapAddCard'));
const EventDetail = lazy(() => import('../pages/website/my-profile/EventDetail'));
const MasterClassPlans = lazy(() => import('../pages/website/masterclass/MasterClassPlans'));
const DeleteAccount = lazy(() => import('../pages/website/my-account/web-settings/DeleteAccount'));
const MasterClassCheckout = lazy(() => import('../pages/website/masterclass/masterClassSubscription/MasterClassCheckout'));

export const RoutesPath = [
  {
    path: pathRoute.web.profile,
    name: "Profile",
    element: Profile,
    isback: false,
  },
  {
    path: `${pathRoute.web.artDetail}/:id`,
    name: "Art Detail",
    element: ArtRoomDetail,
    isback: false,
    isPortfolio: true,
  },
  {
    path: `${pathRoute.web.commentDetail}/:id`,
    name: "Comment Detail",
    element: FeedCommentDetail,
    isback: false,
  },
  {
    path: `${pathRoute.web.checkout}/:id`,
    name: "Checkout",
    element: Checkout,
    isback: false,
  },
];

export const AuthRoutes = [
  {
    id: 1,
    path: pathRoute.auth.login,
    name: "Login",
    element: Login,
    isback: false,
  },
  {
    id: 2,
    path: pathRoute.auth.register,
    name: "Register",
    element: Register,
    isback: false,
  },
  {
    id: 3,
    path: pathRoute.auth.profileSetup,
    name: "Profile Setup",
    element: ProfileSetup,
    isback: false,
  },
  {
    id: 4,
    path: pathRoute.auth.otp,
    name: "OTP",
    element: OTP,
    isback: false,
  },
  {
    id: 5,
    path: pathRoute.auth.forgotPassword,
    name: "Forgot Password",
    element: ForgotPassword,
    isback: false,
  },
  {
    id: 6,
    path: pathRoute.auth.resetPassword,
    name: "Reset Password",
    element: ResetPassword,
    isback: false,
  },
];

export const WebsiteRoutes = [
  {
    id: 1,
    path: pathRoute.auth.social,
    name: "Social",
    element: Social,
    isback: false,
  },
  {
    id: 2,
    path: pathRoute.website.opportunities,
    name: "Opportunities",
    element: Opportunities,
    isback: false,
  },
  {
    id: 3,
    path: `${pathRoute.website.opportunityDetail}`,
    name: "Opportunity Detail",
    element: OpportunityDetail,
    isback: false,
  },
  {
    id: 4,
    path: `${pathRoute.website.userProfile}/:id`,
    name: "User Profile",
    element: UserProfile,
    isback: false,
  },
  {
    id: 5,
    path: pathRoute.website.settings,
    name: "Settings",
    element: Settings,
    isback: false,
  },
  {
    id: 6,
    path: pathRoute.website.searchList,
    name: "Search Result",
    element: SearchList,
    isback: false,
  },
  {
    id: 7,
    path: pathRoute.website.piece,
    name: "Piece",
    element: Piece,
    isback: false,
  },
  {
    id: 8,
    path: pathRoute.website.people,
    name: "People",
    element: People,
    isback: false,
  },
  {
    id: 9,
    path: `${pathRoute.website.artroomDetail}/:id`,
    name: "Art Detail",
    element: ProfileArtRoomDetails,
    isback: false,
    isPortfolio: false,
  },
];

export const privateRoutes = [
  {
    id: 1,
    path: pathRoute.website.masterClass,
    name: "Master Class",
    element: MasterClass,
    isback: false,
  },
  {
    id: 2,
    path: pathRoute.website.myPurchase,
    name: "My Purchase",
    element: MyPurchase,
    isback: false,
  },
  {
    id: 3,
    path: pathRoute.website.mySales,
    name: "My Sales",
    element: MySales,
    isback: false,
  },
  {
    id: 4,
    path: pathRoute.website.myMasterclass,
    name: "My Masterclass",
    element: MyMasterclass,
    isback: false,
  },
  {
    id: 5,
    path: pathRoute.website.myTransactions,
    name: "My Transactions",
    element: MyTransactions,
    isback: false,
  },
  {
    id: 6,
    path: pathRoute.website.paymentMethods,
    name: "Payment Methods",
    element: PaymentMethods,
    isback: false,
  },
  {
    id: 7,
    path: pathRoute.website.stripe,
    name: "Stripe",
    element: StripeMain,
    isback: false,
  },
  {
    id: 8,
    path: pathRoute.website.editProfile,
    name: "Edit Profile",
    element: EditProfile,
  },
  {
    id: 9,
    path: `${pathRoute.website.checkout}/:id`,
    name: "Checkout",
    element: ArtCheckout,
    isback: false,
  },
  {
    id: 10,
    path: pathRoute.website.createPost,
    name: "Create Post",
    element: CreatePost,
    isback: false,
  },
  {
    id: 11,
    path: `${pathRoute.website.masterClassDetail}/:id`,
    name: "Master Class Detail",
    element: MasterClassDetail,
    isback: false,
  },
  {
    id: 12,
    path: pathRoute.website.myProfile,
    name: "My Profile",
    element: MyProfile,
    isback: false,
  },
  {
    id: 13,
    path: `${pathRoute.website.purchaseDetail}/:id`,
    name: "Purchase Detail",
    element: PurchaseDetail,
    isback: false,
  },
  {
    id: 14,
    path: `${pathRoute.website.saleDetail}/:id`,
    name: "Sales Detail",
    element: SaleDetail,
    isback: false,
  },
  {
    id: 15,
    path: pathRoute.website.createEvent,
    name: "Create Event",
    element: CreateEvent,
    isback: false,
  },
  {
    id: 16,
    path: `${pathRoute.website.editPost}/:id`,
    name: "Edit Post",
    element: CreatePost,
  },
  {
    id: 17,
    path: `${pathRoute.website.relatedMasterClassDetail}/:id`,
    name: "Related Master Class Detail",
    element: MasterClassDetail,
    isback: false,
  },
  {
    id: 18,
    path: pathRoute.website.stripeConnect,
    name: "StripeConnect",
    element: StripeConnect,
  },
  {
    id: 19,
    path: pathRoute.website.notifications,
    name: "Notifications",
    element: Notifications,
    isback: false,
  },
  {
    id: 20,
    path: pathRoute.website.addCard,
    name: "AddCard",
    element: WrapAddCard,
    isback: false,
  },
  {
    id: 21,
    path: `${pathRoute.website.eventDetail}/:id`,
    name: "EventDetail",
    element: EventDetail,
    isback: false,
  },
  {
    id: 22,
    path: pathRoute.website.masterClassPlans,
    name: "MasterClassPlans",
    element: MasterClassPlans,
    isback: false,
  },
  {
    id: 23,
    path: pathRoute.website.deleteAccount,
    name: "DeleteAccount",
    element: DeleteAccount,
    isback: false,
  },
  {
    id: 24,
    path: `${pathRoute.website.masterClassSubscription}/:id`,
    name: "MasterClassSubscription",
    element: MasterClassCheckout,
    isback: false,
  },
  {
    id: 25,
    path: `${pathRoute.website.appleCallback}`,
    name: "apple",
    element: Social,
    isback: false,
  },
];