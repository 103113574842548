import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define the user profile state structure
export interface UserProfileState {
  backgroundColor: string | null;
}

// Initial state
const initialState: UserProfileState = {
  backgroundColor: null,
};

// Create Redux slice
export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfileBackground: (state, action: PayloadAction<string | null>) => {
      state.backgroundColor = action.payload;
    },
    resetUserProfile: () => initialState, // Resets to initial state
  },
});

// ✅ Selector to get background color
export const userProfileBackgroundSelector = (state: { userProfile: UserProfileState }) =>
  state.userProfile.backgroundColor;

// Export actions & reducer
export const { setUserProfileBackground, resetUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
