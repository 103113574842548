// src/store.ts
import { Middleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import rootReducers from "./rootReducers";
import { clearUser } from "./slices/userSlice";

const middlewares: Middleware[] = []; // Define any custom middlewares here

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state invariant checks if using non-serializable values
    }).concat(middlewares),
});

export const persistor = persistStore(store);

window.addEventListener("storage", (event) => {
  if (
    event.storageArea === sessionStorage &&
    !sessionStorage.getItem("persist:Artgapi_Web")
  ) {
    // store.dispatch({ type: "user/clearUser" }); // Adjust the action to match your needs
    store.dispatch(clearUser()); // Adjust the action to match your needs
    persistor.purge(); // Clear persisted state
  }
});

const exportStore = { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default exportStore;
