import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import { AuthRoutes, RoutesPath, WebsiteRoutes, privateRoutes } from "./routes";
import { CheckUser } from "../services/profileService";
// import Wrapper from "../components/wrapper/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../redux/slices/userSlice";
import { setProfile } from "../redux/slices/profileSlice";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../redux/slices/siteLoaderSlice";
import Loader from "../components/core/Loader";
import { Interest } from "../interface/Interest";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
// import NotFound from "../components/notfound/NotFound";
// import AuthenticationWrapper from "../components/wrapper/AuthenticationWrapper";
import { pathRoute } from "./pathRoute";
// import WebWrapper from "../components/wrapper/WebWrapper";
// import MyAccount from "../pages/website/my-account/MyAccount";
import ScrollToTop from "../components/web-component/ScrollToTop";
const Wrapper = lazy(() => import("../components/wrapper/Wrapper"));
const MyAccount = lazy(() => import("../pages/website/my-account/MyAccount"));
const NotFound = lazy(() => import("../components/notfound/NotFound"));
const WebWrapper = lazy(() => import("../components/wrapper/WebWrapper"));
const AuthenticationWrapper = lazy(
  () => import("../components/wrapper/AuthenticationWrapper")
);

const AppRoutes = () => {
  const [isValid, setIsValid] = useState(false);
  let location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location?.search);
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const isLoading = useSelector(siteLoaderSelector); // Get the loader state
  const RouteFallback = () => <Loader />;
  useEffect(() => {
    const validateToken = async () => {
      if (token && token.trim() && !userId) {
        dispatch(setToken(token));
        dispatch(showLoader()); // Show loader before API call

        try {
          const data = { token };
          const response = await CheckUser(data);

          if (response?.data?.data) {
            let responseData = response?.data?.data;
            let data = {
              avatar: responseData?.avatar,
              country: responseData?.country,
              followers: responseData?.followers,
              nickname: responseData?.nickname,
              description: responseData?.description,
              post: responseData?.totalPublicationsCount,
              id: responseData?.id,
              phoneNb: responseData?.phoneNb,
              interest: responseData?.interest?.map((item: Interest) => ({
                id: item.id,
                interest: {
                  title: item.interest.title,
                  interestCategoryId: item.interest.interestCategoryId,
                  status: item.interest.status,
                },
              })),
              backgroundColor: responseData?.backgroundColor,
              coverColor: responseData?.coverColor,
              coverImage: responseData?.coverImage,
              textColor: responseData?.textColor,
            };
            dispatch(setProfile(data));
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        } catch (error) {
          console.error("Error validating token:", error);
          setIsValid(false);
        } finally {
          dispatch(hideLoader()); // Hide loader after API call
        }
      } else {
        setIsValid(false);
      }
    };

    validateToken();
  }, [token, dispatch, userId]);

  useEffect(() => {
    // Preload auth-related components if token exists
    if (token) {
      import("../components/wrapper/Wrapper");
      import("../pages/website/my-account/MyAccount");
    }
  }, [token]);

  const isSidebarInside = location?.pathname?.includes("my-account");
  console.log("AuthRoutes", location, isSidebarInside);
  // Only render Routes when token check is complete
  return (
    <>
      <ScrollToTop />
      {isLoading && <Loader />} {/* Show loader if loading */}
        <Suspense fallback={<RouteFallback />}>
          <Routes>
            {isValid ? (
              <>
                <Route element={<Wrapper />}>
                  <Route
                    path="/"
                    element={<Navigate to={pathRoute.web.profile} replace />}
                  />
                  {RoutesPath.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.element />}
                    />
                  ))}
                </Route>
                <Route path="*" element={<NotFound iswebsite={false} />} />
              </>
            ) : (
              <>
                {WebsiteRoutes?.map((route, id) => (
                  <Route key={route.path} element={<WebWrapper />}>
                    {/* for inside side bar  */}
                    {isSidebarInside ? (
                      <Route key={id} element={<MyAccount />}>
                        <Route
                          key={id}
                          path={route.path}
                          element={<route.element />}
                        />
                      </Route>
                    ) : (
                      <Route
                        key={id}
                        path={route.path}
                        element={<route.element />}
                      />
                    )}
                  </Route>
                ))}

                {privateRoutes?.map((route, id) => (
                  <Route key={route.path} element={<PrivateRoute />}>
                    <Route key={route.path} element={<WebWrapper />}>
                      {/* for inside side bar  */}
                      {isSidebarInside ? (
                        <Route element={<MyAccount />}>
                          <Route
                            key={id}
                            path={route.path}
                            element={<route.element />}
                          />
                        </Route>
                      ) : (
                        <Route
                          key={id}
                          path={route.path}
                          element={<route.element />}
                        />
                      )}
                    </Route>
                  </Route>
                ))}

                {AuthRoutes?.map((route, id) => (
                  <Route key={route.path} element={<AuthenticationWrapper />}>
                    <Route
                      key={id}
                      path={route.path}
                      element={
                        <GuestRoute>
                          <route.element />
                        </GuestRoute>
                      }
                    />
                  </Route>
                ))}

                <Route path="*" element={<NotFound iswebsite={true} />} />
              </>
            )}
          </Routes>
        </Suspense>
    </>
  );
};

export default AppRoutes;
